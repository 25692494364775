@import "../../scss/variables";

.MuiDateCalendar-root {
  height: 100% !important;
  .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
    min-height: 200px;
  }
}

.MuiPickersToolbar-root.MuiDatePickerToolbar-root.MuiPickersLayout-toolbar {
  display: none;
}

.custom-date-picker {
  &.error-date-border {
    fieldset {
      border-color: $error !important;
    }
    &:hover {
      fieldset {
        border-color: $error !important;
      }
    }
  }
  .MuiInputLabel-outlined {
    width: 9.5rem !important;
    background-color: white;
    padding: 0 4px;
    transform: translate(14px, -6px) scale(0.75);
    z-index: 1;
    &.Mui-focused {
      color: $primaryColor !important;
      span {
        color: $primaryColor !important;
      }
    }
    &.Mui-error {
      color: $titlesColor;
    }
  }
}

.MuiOutlinedInput-root {
  fieldset {
    border-color: inherit !important;
  }
}
