@import "../../../scss/variables";

.delete-account-modal
  > div.MuiDialog-container
  > div
  > div.MuiDialogActions-root {
  display: flex;
  justify-content: center;
  @media #{$media-mobile} {
    padding-right: 24px;
    padding-left: 24px;
    flex-direction: row !important;
  }
  @media #{$media-mobile-md} {
    flex-direction: column-reverse !important;
  }
  @media #{$media-mobile-sm} {
    flex-direction: column-reverse !important;
  }
  > button.MuiButtonBase-root {
    width: 40% !important;
    white-space: nowrap;
    @media #{$media-mobile} {
      width: 100% !important;
    }
  }
}
