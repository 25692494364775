@import "../../scss/variables";

.empty-state-container {
  @media #{$media-desktop-lg} {
    height: 68dvh;
  }
  @media #{$media-desktop} {
    height: 63vh;
  }

  @media #{$media-tablet} {
    height: 70vh;
  }
}

.rowView {
  flex-direction: row !important;
  justify-content: center !important;
  padding: 1rem;
  img {
    order: 1 !important;
    max-height: 15rem;
  }
  .emptyPlaceholder-content {
    order: 0 !important;
    margin-bottom: 1rem !important;
  }
}
.colView {
  flex-direction: column !important;
  text-align: center;
  justify-content: center !important;
}

.empty-state-create-button {
  width: fit-content !important;
}
