@import "./../../../../scss/variables";

.category-exporters-section-container {
  .exporter-card-container {
    .exporter-details {
      display: flex;
      gap: 1rem;
      @media #{$media-mobile} {
        flex-direction: column;
      }
      .profile-img {
        width: 9rem;
        height: 9rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
    .exporter-products-container {
      @media #{$media-mobile} {
        .exporter-product-card {
          width: 50% !important;
        }
      }
      @media #{$media-mobile-sm} {
        .exporter-product-card {
          width: 100% !important;
        }
      }
    }
  }
  .category-exporters-slider-container {
    .custom-swiper-button-next {
      right: -20px !important;
      @media #{$media-mobile} {
        right: -12px !important;
      }
    }
    .custom-swiper-button-prev {
      left: -20px !important;
      @media #{$media-mobile} {
        left: -12px !important;
      }
    }
  }
}
