@import "../../scss/variables";

.quill {
  padding: 0 !important;

  .ql-toolbar {
    border-radius: 8px 8px 0 0;
  }
  .ql-container {
    border-radius: 0 0 8px 8px;
    padding-bottom: 50px !important;
    overflow-y: auto;
  }
  &.rich-text-only-input {
    .ql-container {
      height: 120px !important;
      padding-bottom: 5px !important;
    }
  }
  .ql-editor {
    font-size: 14px;
    font-family: "Poppins-Regular";
    @media #{$media-desktop-xxl} {
      font-size: 18px;
    }
  }
  .ql-toolbar,
  .ql-container {
    border-color: #dee2e6 !important;
  }

  &.rich-text-focused {
    .ql-toolbar,
    .ql-container {
      border-color: rgba(0, 0, 0, 0.175) !important;
    }
    .ql-container {
      border-color: rgba(0, 0, 0, 0.175) !important;
    }
    &.rich-text-error {
      .ql-toolbar,
      .ql-container {
        box-shadow: 0 0 0 0.8px $error;
      }
    }
  }
  &.rich-text-error {
    .ql-toolbar,
    .ql-container {
      border-color: $error !important;
    }
  }
}
.input-wrapper {
  .rich-text-input {
    position: relative;
    .rich-text-label {
      transform: scale(0.85);
      position: absolute;
      top: -11px;
      left: -2px;
      background-color: $white;
      padding: 0 2.5rem 0 5px;
      &.focused-label {
        color: $primaryColor !important;
      }
    }
  }
}
.ql-editor.ql-blank::before {
  color: #9e9e9e;
  font-family: "Poppins-Regular";
  font-style: normal;
}
