@import "../../../../scss/variables";

.exporter-profile-container {
  .header-section {
    width: 100%;
    height: 14rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 10rem;
    @media #{$media-mobile} {
      height: 10rem;
      margin-bottom: 5rem;
    }
    .profile-img {
      width: 13rem;
      height: 13rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: -6.5rem;
      @media #{$media-mobile} {
        width: 9rem;
        height: 9rem;
        bottom: -4.5rem;
      }
    }
    &.size-cover {
      background-size: cover;
    }
    &.size-contain {
      background-size: contain;
    }
  }
  .banner-container {
    background-color: $blueChipBackground !important;
    * {
      color: $primaryColor;
    }
  }
  div.page-title {
    @media #{$media-mobile-md} {
      display: flex !important;
      flex-direction: column !important;
      gap: 1rem;
    }
    @media #{$media-mobile} {
      .title-buttons-container {
        display: flex !important;
        flex-direction: row !important;
        gap: 1rem;
        * {
          font-size: small !important;
        }
        svg {
          width: 1rem !important;
          height: 1rem !important;
        }
      }
    }
    @media #{$media-mobile-sm} {
      .title-buttons-container {
        * {
          font-size: x-small !important;
        }
        svg {
          width: 0.9rem !important;
          height: 0.9rem !important;
        }
      }
    }
  }
  .showcases-section {
    .title-add-showcase-container {
      @media #{$media-mobile} {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
  .showcase-img-products-container {
    @media #{$media-desktop-lg} {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
    .img-container {
      .showcase-image {
        width: 100% !important;
        height: 100% !important;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;
        .showcase-info {
          border-radius: 8px;
          width: 100%;
          height: 100%;
          background-color: rgba($color: $black, $alpha: 0.3);
          .showcase-description {
            @media #{$media-mobile} {
              font-size: 1rem !important;
            }
            @media #{$media-mobile-sm} {
              font-size: 0.75rem !important;
            }
          }
          .showcase-name {
            @media #{$media-mobile-sm} {
              font-size: 1.5rem !important;
            }
          }
        }
      }
      width: 28rem;
      @media #{$media-desktop-lg} {
        min-width: 30rem;
      }
      height: 34.9rem;
      @media #{$media-desktop} {
        height: 37.9rem;
        width: 30rem;
        padding-bottom: 3rem;
      }
      @media #{$media-tablet} {
        height: 35.9rem;
        padding-bottom: 1rem;
      }
      @media #{$media-mobile} {
        height: 33.6rem;
        width: 100%;
        padding-bottom: 3rem;
      }
      @media #{$media-mobile-sm} {
        width: 100%;
        height: 25.5rem;
        padding-bottom: 3rem;
      }
    }
  }
  .products-buttons-container {
    .products-container {
      .single-product {
        background-color: $faintgreyBg;
        height: 17rem;
        width: 30%;
        @media #{$media-desktop} {
          width: 48%;
        }
        @media #{$media-tablet} {
          width: 48%;
        }
        @media #{$media-mobile} {
          width: 100%;
        }
        border: 1px dashed $grey;
        &.filled-product {
          border: none;
          * {
            max-height: 100% !important;
          }
        }
        &.first-product-placeholder {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          * {
            color: $grey;
          }
          &:hover {
            background-color: $disabledGreyColor;
          }
          &.disabled {
            cursor: not-allowed !important;
            background-color: $disabledGreyColor;
          }
        }
      }
    }
  }

  .showcase-separator {
    width: 100%;
    height: 1px;
    background-color: $lightGreySeparator;
  }
  .showcase-products-card {
    background-color: $blueChipBackground !important;
  }
}
