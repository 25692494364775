@import "../../scss/variables";

.stepper-wrapper {
  .MuiStepper-root {
    align-items: center;
  }
  .step-count {
    width: 2.5rem;
    height: 2.5rem;
  }
  .MuiStepConnector-root {
    top: 15px;
    .MuiStepConnector-line {
      border-top-width: 2px;
    }
    &.Mui-active,
    &.Mui-completed {
      .MuiStepConnector-line {
        border-color: $successColor;
      }
    }
  }
}
