html,
body {
  font-size: 14px;
  font-family: "Poppins-Regular", sans-serif;
  @media #{$media-desktop-xxl} {
    font-size: 18px;
  }
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;
}

.font-regular {
  font-family: "Poppins-Regular" !important;
}

.font-medium {
  font-family: "Poppins-Medium" !important;
}

.font-semibold {
  font-family: "Poppins-Semibold" !important;
}

.font-bold {
  font-family: "Poppins-Bold" !important;
}

.font-light {
  font-family: "Poppins-Light" !important;
}
.font-italic {
  font-family: "Poppins-Italic" !important;
}
.text-grey {
  color: $greyText !important;
}
.text-primary {
  color: $primaryColor !important;
}
.text-dark-blue {
  color: $darkBlue !important;
}
.title-color {
  color: $titlesColor !important;
}
.text-color {
  color: $textColor !important;
}
.medium-text-color {
  color: $mediumGreyText !important;
}
.light-text-color {
  color: $lightTextColor !important;
}
.text-light-grey {
  color: $lightGrey !important;
}
.text-error {
  color: $error !important;
}
.text-light-blue {
  color: $lightFaintBlue !important;
}
.text-grey-disabled {
  color: $disabledGreyColor !important;
}
.text-green {
  color: $successColor !important;
}
