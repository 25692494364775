@import "../../../scss/variables";

.home-navbar-container {
  position: fixed;
  top: 0;
  @media #{$media-mobile} {
    position: relative;
  }
  width: 100%;
  z-index: 3;
  .MuiAppBar-root {
    box-shadow: none !important;
  }
  @media #{$media-mobile} {
    position: unset;
  }
  .logo-container {
    width: 10.75rem;
  }
  .subCategories-section {
    &::after {
      content: "";
      width: 2px;
      height: 20px;
      background-color: $white;
      display: inline-block;
      position: relative;
      left: 2rem;
    }
    @media #{$media-mobile} {
      &::after {
        content: none;
      }
    }
  }
  @media #{$media-mobile} {
    .navigation-list-container {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none !important;
      }
      .navigation-list {
        width: max-content;
      }
    }
  }
  .MuiButtonBase-root {
    font-size: 1rem !important;
  }
}

#sub-categories-menu {
  .MuiPaper-root {
    max-width: 20rem !important;
    max-height: 25rem !important;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent;
    }

    &::-webkit-scrollbar {
      width: 7px;
      background: $faintGrey;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: white;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 15px;
    }
    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 15px;
    }
  }
  .MuiList-root {
    padding: 1.5rem 1rem;
    padding-right: 1rem !important;
    .MuiMenuItem-root {
      text-wrap: wrap;
      padding: 0.6rem 1rem;
    }
  }
}
