$widthValues: (
  "10",
  "20",
  "30",
  "40",
  "45",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100"
);
// different width sizes
@each $widthVal in $widthValues {
  .w-#{$widthVal} {
    width: #{str-insert($widthVal, $insert: "%", $index: -1)};
  }
}

$sizes: (
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  35,
  40,
  44,
  45,
  48,
  50
);
@each $size in $sizes {
  .fsize-#{$size} {
    font-size: ($size / 14) + rem !important;
  }
}

$weights: (300, 400, 500, 600, 700);
@each $weight in $weights {
  .fweight-#{$weight} {
    font-weight: $weight !important;
  }
}

$borderRadiuses: (4, 5, 8, 10, 12, 14, 16, 18, 20, 50);
@each $radius in $borderRadiuses {
  .border-radius-#{$radius} {
    border-radius: $radius + px !important;
  }
}

$linesNo: (1, 2);
@each $lineNo in $linesNo {
  .truncate-lines-#{$lineNo} {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: $lineNo; /* number of lines to show */
    line-clamp: $lineNo;
    -webkit-box-orient: vertical;
  }
}
