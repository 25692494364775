@import "./../../../scss/variables";

.single-product-container {
  &:hover {
    color: $primaryColor;
    border: 1px solid $primaryColor;
    cursor: pointer;

    .product-price {
      font-weight: bold;
      color: black !important;
    }
  }
}

.selected-product {
  border: 1px solid $primaryColor;
  cursor: pointer;
  .product-price {
    color: black !important;
    font-weight: bold;
  }
  &:hover {
    color: $primaryColor;
    cursor: pointer;
    .product-price {
      font-weight: bold;
    }
  }
}
