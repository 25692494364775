@import "../../../../../scss/variables";

.add-items-container {
  #scrollableModal > div {
    overflow-x: hidden;
    > div {
      > div.single-product-container {
        &:hover {
          color: $primaryColor;
          border: 1px solid $primaryColor;
          cursor: pointer;

          .product-price {
            font-weight: bold;
            color: black !important;
          }
        }
      }
      > div.selected-product {
        border: 1px solid $primaryColor;
        cursor: pointer;
        .product-price {
          color: black !important;
          font-weight: bold;
        }
      }
    }
  }
}

.product-container {
  position: relative;
  .unpublished-cover {
    width: auto;
    height: fit-content !important;
    z-index: 2;
    top: 2px;
    left: 12px;
    position: absolute;
  }
}

.add-products-modal > div.MuiDialog-container > div {
  @media #{$media-desktop-lg} {
    width: 45%;
  }
  @media #{$media-desktop} {
    width: 70%;
  }
  @media #{$media-tablet} {
    width: 100%;
  }
  @media #{$media-mobile} {
    width: 65%;
  }
  @media #{$media-mobile-sm} {
    width: 80%;
  }
  > div.MuiDialogActions-root {
    @media #{$media-mobile} {
      padding-right: 24px;
      padding-left: 24px;
    }
    display: flex;
    justify-content: center;
    flex-direction: row !important;
    > button.MuiButtonBase-root {
      width: 20% !important;
      @media #{$media-mobile} {
        width: 100% !important;
      }
    }
  }
}
