@import "../../../scss/variables";

.products-table-container {
  table {
    thead {
      th {
        white-space: nowrap;
      }
      th:nth-child(1) {
        width: 20% !important;
      }
      th:nth-child(5) {
        padding-left: 3rem !important;
      }
      th:nth-child(6) {
        text-align: center;
      }
    }
    tbody > tr:last-child > td {
      border-bottom: 0 !important;
    }
    tbody {
      tr {
        td {
          white-space: nowrap;
        }
      }
    }
  }
  .table-header {
    @media #{$media-mobile-sm} {
      display: flex;
      flex-direction: column !important;
      gap: 1rem;
    }
  }
}

.filter-content-container {
  height: 100% !important;
}

.product-img {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
}

.products-list {
  div.page-title {
    @media #{$media-mobile} {
      display: flex !important;
      flex-direction: column !important;
      gap: 1rem;
    }
    @media #{$media-mobile-md} {
      .title-buttons-container {
        display: flex !important;
        flex-direction: row !important;
        gap: 1rem;
        * {
          font-size: small !important;
        }
      }
    }
    @media #{$media-mobile-sm} {
      .title-buttons-container {
        * {
          font-size: x-small !important;
        }
        svg {
          width: 0.9rem !important;
        }
      }
    }
  }
}
