@import "../../../../scss/variables";

.guide-border {
  border-right: 1px solid $greyGuideBorder !important;
  @media #{$media-mobile} {
    border-right: 0 !important;
    border-bottom: 1px solid $greyGuideBorder !important;
  }
  &.responsive-border {
    @media #{$media-mobile} {
      border-right: 0 !important;
      border-bottom: 1px solid $greyGuideBorder !important;
    }
  }
  &.responsive-md-border {
    @media #{$media-desktop} {
      border-right: 0 !important;
    }
    @media #{$media-tablet} {
      border-right: 0 !important;
    }
  }
}

.icon-container {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: -20px;
  left: 35%;
  @media #{$media-mobile} {
    left: 43%;
  }
  @media #{$media-mobile-sm} {
    left: 40%;
  }
}
.img-container {
  img {
    width: 90%;
    height: 100%;
    @media #{$media-mobile} {
      width: 60%;
    }
    @media #{$media-mobile-sm} {
      width: 100%;
    }
  }
}
