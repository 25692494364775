@import "../../../../scss/variables";

.add-edit-product-request-form-container {
  .add-edit-product-request-form {
    .guide-container {
      background-color: $blueChipBackground;
    }
    .data-container {
      .required-quantity-block,
      .budget-range-block {
        .inputs-container {
          .product-small-form-field {
            width: calc(33% - 2rem);
            @media #{$media-desktop} {
              width: 100% !important;
            }
            @media #{$media-tablet} {
              width: calc(33% - 2rem) !important;
            }
            @media #{$media-mobile} {
              width: 100% !important;
            }
          }
          .product-form-field {
            width: calc(50% - 2rem);
            @media #{$media-desktop} {
              width: 100% !important;
            }
            @media #{$media-tablet} {
              width: calc(50% - 2rem) !important;
            }
            @media #{$media-mobile} {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
