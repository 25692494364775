@import "../../scss/variables";

.modal-container {
  div.Modal-container > div.MuiDialog-container > div.MuiPaper-root {
    border-radius: 10px !important;
  }
  .container {
    @media #{$media-mobile-sm} {
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
      .modal-cancel-btn,
      .modal-confirm-btn {
        margin: 0 !important;
        width: 90% !important;
      }
    }
    @media #{$media-mobile} {
      .modal-cancel-btn,
      .modal-confirm-btn {
        margin: 0 !important;
        width: 70% !important;
      }
    }
    @media #{$media-mobile} {
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
      .modal-cancel-btn.product-success-cancel,
      .modal-confirm-btn.product-success-confirm {
        width: 70% !important;
        margin: 0 !important;
      }
    }
  }
  .modal-cancel-btn {
    color: $grey !important;
    border-color: $grey !important;
    border-radius: 10px !important;
    width: 30%;
    &:hover {
      color: $grey !important;
    }
  }
  .modal-cancel-btn.product-success-cancel {
    width: 35% !important;
  }

  .modal-confirm-btn {
    width: 30%;
    border-radius: 10px !important;
  }
  .modal-confirm-btn.product-success-confirm {
    width: 35% !important;
  }

  .MuiDialog-paper {
    border-radius: 10px !important;
  }
}
