@import "./../../../../scss/variables";

.edit-company-container {
  .company-details-container,
  .contact-info-container {
    .company-inputs-container {
      gap: 2rem;
      .company-form-field {
        width: calc(50% - 2rem);
        @media #{$media-tablet} {
          width: 100% !important;
        }
      }
    }
  }
  .company-logo-container {
    position: relative !important;
    margin-bottom: 10rem;
    @media #{$media-mobile} {
      margin-bottom: 8rem;
    }
    .company-logo {
      width: 13rem;
      height: 13rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: -8.5rem;
      z-index: 10 !important;
      @media #{$media-mobile} {
        width: 9rem;
        height: 9rem;
        bottom: -6.5rem;
      }
    }

    .company-cover-img-container {
      min-height: 14rem;
      max-height: 14rem;
      height: 14rem;
    }
  }
  .form-buttons-container {
    @media #{$media-mobile} {
      width: 100% !important;
      flex-wrap: nowrap !important;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
