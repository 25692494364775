@import "../../../scss/variables";

.product-requests-container {
  .product-requests-section {
    position: relative;
    .product-requests-cover-image {
      min-height: 11.25rem;
      background-position: center;
      background-repeat: no-repeat;
      &.size-cover {
        background-size: cover;
      }
      &.size-contain {
        background-size: contain;
      }
    }
  }
  .border-bottom {
    border-bottom: 2px solid $faintGrey;
  }
  .filters-button {
    display: none !important;
  }
  @media #{$media-tablet} {
    .filters-col {
      display: none;
    }
    .filters-button {
      display: inline !important;
    }
  }
  .product-requests-filter-button {
    @media #{$media-tablet} {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
  .filters-col {
    background-color: $faintgreyBg;
    .MuiPaper-root {
      background-color: $faintgreyBg;
    }
  }
}
