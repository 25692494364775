@import "../../scss/variables";

.dropdown-menu-list {
  svg {
    color: white !important;
  }
}
.dropdown-menu-wrapper {
  .MuiPaper-root {
    border-radius: 10px !important;
    width: fit-content !important;
  }
  .MuiList-root {
    padding: 0.5rem;
  }
  .menu-item {
    width: 100% !important;
    padding: 1rem;
    color: $textColor !important;
    &:hover {
      color: $primaryColor !important;
      background-color: transparent;
      path {
        stroke: $primaryColor !important;
      }
    }
  }
  ul > div:last-child > div > li {
    color: $lightGrey !important;
    padding-right: 2rem;
    padding-left: 2rem;
    border-top: 1px solid $borderColor;
    &.single-item {
      border: none !important;
    }
  }
}
