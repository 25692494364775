@import "../../scss/variables";

.switch-role-btn {
  height: 100% !important;
  background-color: transparent !important;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid $white !important;
  color: $white;
  &:hover {
    cursor: pointer;
  }
  p {
    @media #{$media-mobile} {
      font-size: 0.8rem !important;
    }
  }
}
