@import "../../../scss/variables";

.product-details-container,
.exporter-product-details-container {
  .product-info-section {
    max-height: 34rem;
    @media #{$media-desktop} {
      max-height: 26rem;
    }
    @media #{$media-tablet} {
      max-height: 29rem;
    }
    @media #{$media-mobile} {
      max-height: fit-content;
    }
    .contact-info-width {
      width: 70%;
      @media #{$media-desktop} {
        width: 100%;
      }
    }
    .contact-img-container {
      width: 20%;
      @media #{$media-desktop} {
        width: 40%;
      }
      @media #{$media-tablet} {
        width: 40%;
      }
      @media #{$media-mobile} {
        width: 100%;
      }
    }
  }
  .overview-section,
  .product-video-section,
  .keyword-section {
    border: 1px solid $faintGrey;
    border-radius: 8px !important;
  }
  .overview-section {
    .specs-container {
      @media #{$media-mobile} {
        .spec-name {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .product-video-section {
    .video-react-video,
    .video-react-control-bar,
    .video-react-controls-enabled {
      border-radius: 10px;
    }
  }
}

.exporter-product-details-container {
  background-color: $white !important;
  border-radius: 10px !important;
  .price-ranges-container {
    @media #{$media-desktop} {
      display: flex;
      flex-direction: row !important;
      row-gap: 0.5rem;
    }
    @media #{$media-tablet} {
      display: flex;
      flex-direction: row !important;
      row-gap: 0.5rem;
    }
    @media #{$media-mobile} {
      display: flex;
      flex-direction: column !important;
      row-gap: 0.5rem;
      .range-key {
        width: 50% !important;
      }
      .range-value {
        width: 50% !important;
      }
    }
    @media #{$media-mobile-sm} {
      display: flex;
      flex-direction: column !important;
      row-gap: 0.5rem;
      .range-key {
        width: 70% !important;
      }
      .range-value {
        width: 30% !important;
      }
    }
  }
}
