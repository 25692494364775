@import "../../scss/variables";

.UserDataDropDown-button-wrapper {
  background-color: rgba($color: white, $alpha: 0.2);
  &:hover {
    background-color: rgba($color: white, $alpha: 0.3);
  }
  .user-drop-down-btn {
    font-size: 1rem !important;
  }
  .dropdown-menu-list {
    color: $white;
    padding: 0 !important;
    .MuiButtonBase-root {
      color: $white;
      text-transform: capitalize !important;
      background-color: transparent !important;
      padding: 0.4rem !important;
      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
@media #{$media-mobile} {
  div.UserDataDropDown-button-wrapper {
    padding: 0 0.5rem !important;
    justify-content: center !important;
  }
  .account-circle {
    width: 80% !important;
  }
  .admin-avatar {
    height: fit-content;
    width: 75%;
  }
}
div.UserDataDropDown-button-wrapper {
  @media #{$media-mobile} {
    padding: 0rem !important;
  }
  @media #{$media-mobile-md} {
    padding: 0rem !important;
  }
  @media #{$media-mobile-sm} {
    padding: 0rem !important;
  }
}
