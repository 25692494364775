@import "../../../scss/variables";

.contact-us-container {
  .contact-us-cover-image {
    min-height: 21.8rem;
    background-position: center;
    background-repeat: no-repeat;
    &.size-cover {
      background-size: cover;
    }
    &.size-contain {
      background-size: contain;
    }
  }
}
