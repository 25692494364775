@import "../../../scss/variables";

.number-range-filter {
  .range-inputs {
    .range-input {
      &.MuiInputBase-root {
        background-color: $white;
      }
    }
    @media #{$media-tablet} {
      width: 80%;
    }
    @media #{$media-mobile-md} {
      width: 100%;
    }
    @media #{$media-mobile-sm} {
      width: 110%;
    }
  }
  .range-input {
    .MuiInputBase-input {
      padding: 0.5rem;
      text-align: center;
    }
  }
  .collabsible-filter {
    .MuiAccordionSummary-content {
      margin: 0.5rem 0 !important;
    }
    .MuiAccordionSummary-root {
      min-height: 35px !important;
      padding: 0 !important;
    }
    .MuiAccordionDetails-root {
      padding: 0 !important;
    }
  }
}
