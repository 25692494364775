@import "../../../scss/variables";

.exporter-card-grid {
  position: relative;
  &.disabled {
    &:hover {
      .card-img-container,
      .card-label,
      .country,
      .description {
        opacity: 0.3;
      }
      .disabled-hovering-layout {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  .disabled-hovering-layout {
    background-color: $transparentPrimaryColor;
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .card-label {
    div.MuiBox-root {
      // width: fit-content !important;
      span {
        // width: min-content !important;
      }
    }
  }
}
