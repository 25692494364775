@import "../../../scss/variables";

.dropdown-container {
  max-height: 70dvh;
  width: 80%;
  @media #{$media-mobile} {
    width: 100%;
    max-height: 50dvh;
  }
}
.category-btn {
  padding: 12px;

  @media #{$media-mobile} {
    padding: 10px;
  }
}

.category-btn:hover,
.category-btn.active {
  background-color: $lightBlue !important;
  color: $primaryColor !important;
  border-radius: 10px;
}

.subcategories-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  row-gap: 20px;
  max-height: 80%;
  @media #{$media-tablet-sm} {
    grid-template-columns: 1fr;
  }
}
.dropdown-list {
  width: 25%;
  @media #{$media-tablet} {
    width: 40%;
  }
  @media #{$media-mobile} {
    width: 50%;
  }
}
.subcategory-image {
  width: 50px;
  height: 50px;
  padding: 5px 2.5px;
}
