@import "../../../scss/variables";

.change-currency-menu-wrapper {
  .change-currency-btn {
    background-color: rgba($color: white, $alpha: 0.2) !important;
    &:hover {
      background-color: rgba($color: white, $alpha: 0.3) !important;
    }
    font-size: 1rem !important;
  }
}
.change-currency-menu {
  .MuiOutlinedInput-input {
    padding: 0.5rem;
  }
  .MuiSvgIcon-root {
    color: $mediumGreyText;
  }
  .MuiMenuItem-root {
    &.no-hover {
      &:hover {
        background-color: $white !important;
      }
    }
    &.Mui-focusVisible {
      background-color: $white !important;
    }
  }
  .MuiPaper-root {
    max-width: 20rem !important;
    max-height: 25rem !important;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 7px;
      background-color: $faintGrey;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: white;
    }
    &::-webkit-scrollbar-track-piece:end {
      background-color: transparent;
      margin-bottom: 15px;
    }
    &::-webkit-scrollbar-track-piece:start {
      background-color: transparent;
      margin-top: 15px;
    }
  }
}
