@import "../../../scss/variables";

.home-page-container {
  .hero-section-container {
    .slide-description {
      margin-left: 7rem;
      .explore-btn {
        width: 14rem;
      }
      @media #{$media-desktop} {
        .ex-gates {
          font-size: 2.8rem !important;
        }
        .slogan {
          font-size: 2.3rem !important;
        }
      }
      @media #{$media-tablet} {
        margin-left: 5rem;
        .ex-gates {
          font-size: 2rem !important;
        }
        .slogan {
          font-size: 1.5rem !important;
        }
        .description {
          font-size: 1rem !important;
        }
        .explore-btn {
          width: 10rem;
        }
      }
      @media #{$media-mobile} {
        margin: 0 2rem;
      }
    }
    @media #{$media-mobile} {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }
  }
  .sub-categories-slider,
  .products-slider,
  .exporters-slider {
    .slick-prev {
      left: 0px;
    }
    [dir="rtl"] .slick-prev {
      right: 0px;
      left: auto;
    }
    .slick-next {
      right: 0px;
    }
    [dir="rtl"] .slick-next {
      right: auto;
      left: 0px;
    }
  }
  .sub-categories-section-container {
    .sub-category-card {
      .card-img-container {
        min-height: 10rem;
        @media #{$media-tablet} {
          min-height: 5rem;
        }
      }
      .card-label {
        min-height: 4rem;
      }
    }
  }
  .explore-section-container {
    .explore-card {
      width: 49%;
      @media #{$media-tablet} {
        width: 100%;
      }
    }
  }

  .packages-section-container {
    .packages-section {
      margin-top: -5rem;
    }
    .description {
      min-height: 7.5rem;
    }
    .most-popular {
      top: 1rem;
      right: 1rem;
    }
    .package-card.popular {
      background-color: $primaryColor !important;
      .package-text {
        color: $white !important;
      }
      .package-btn,
      .package-btn:hover {
        border-color: $white !important;
        color: $white !important;
      }
      .most-popular {
        background-color: $white !important;
        color: $darkBlue !important;
      }
    }
  }
  .contact-section-container {
    position: relative;
    .green-bg {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 20%;
      z-index: -1;
      @media #{$media-tablet} {
        top: 0;
      }
    }
    .content {
      @media #{$media-desktop-lg} {
        margin-bottom: 10% !important;
      }
    }
  }
}
