@import "../../scss/variables";

.pagination-wrapper {
  .MuiButtonBase-root {
    background-color: white;
  }
  .MuiPaginationItem-page.Mui-selected,
  .MuiPaginationItem-page.Mui-selected:hover {
    font-weight: 600;
    background-color: $primaryColor;
    color: $white;
    border-radius: 8px !important;
  }

  .MuiPaginationItem-root {
    margin: 0 !important;
  }
  .MuiPagination-ul > * {
    margin: 0.2rem;
  }
}
