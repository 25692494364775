@import "../../../scss/variables";
.edit-profile-container {
  .profile-img-data-container {
    position: relative;
    .profile-img {
      width: 10rem;
      height: 10rem;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .data-container {
      .not-editable-fields {
        word-break: break-all;
      }
      @media #{$media-mobile} {
        .not-editable-fields {
          word-break: break-all;
          &.importer-side {
            padding-left: 0rem !important;
            display: flex;
            flex-direction: column-reverse;
          }
        }
      }
    }
  }
  .form-buttons-container {
    @media #{$media-mobile} {
      width: 100% !important;
      flex-wrap: nowrap !important;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
