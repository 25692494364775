@import "../../scss/variables";

.input-wrapper {
  .MuiOutlinedInput-root {
    border-radius: 8px !important;
    &.Mui-error {
      border-color: $error !important;
      fieldset {
        border-color: $error !important;
      }
    }
    &.Mui-disabled {
      background-color: $uploadButtonBackground !important;
    }
  }

  .MuiInputLabel-root {
    &.Mui-focused {
      color: $primaryColor !important;
      span {
        color: $primaryColor !important;
      }
    }
    &.Mui-error {
      color: $titlesColor;
    }
  }
  .MuiIconButton-edgeEnd {
    margin-right: 10px;
  }
  &.file-input-wrapper {
    @media #{$media-mobile-sm} {
      div {
        display: flex !important;
        flex-direction: column !important;
      }
    }
  }
}
input::file-selector-button {
  background-color: $uploadButtonBackground;
  border-radius: 8px;
  font-weight: bold;
  padding: 0.5rem 2rem;
  font-size: 12px;
  color: $lightGrey;
  border: thin solid $grey;
}

.Mui-disabled {
  * {
    cursor: not-allowed !important;
  }
}

.MuiFormLabel-root.MuiInputLabel-root {
  transform: translate(14px, -9px) scale(0.85);
}
.pass-container {
  .MuiFormLabel-root.MuiInputLabel-root {
    transform: translate(14px, -10px);
  }
}
