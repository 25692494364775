@import "../../scss/variables";

.drawer-modal-container {
  div.Modal-container > div.MuiDialog-container > div.MuiPaper-root {
    border-radius: 1.25rem !important;
  }
  .modal-cancel-btn {
    color: $primaryColor !important;
  }
  .modal-cancel-btn:hover {
    color: $primaryColor !important;
  }

  .MuiDialog-paper {
    position: absolute !important;
    right: 0px !important;
    top: 0px !important;
    height: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
  }

  .MuiDialog-paper.modal-close {
    -webkit-transform: translate(100%, 0) scale(1);
    transform: translate(100%, 0) scale(1);
  }
}
