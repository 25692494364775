@import "../../../scss/variables";

.change-currency-modal {
  .continue-primary-btn {
    color: $primaryColor !important;
    border-color: $primaryColor !important;
    &:hover {
      color: $primaryColor !important;
      border-color: $primaryColor !important;
    }
  }
}
