@import "../../../scss/_variables.scss";

.login-page {
  @media #{$media-mobile-sm} {
    .extra-actions-container {
      flex-direction: column;
      align-items: start !important;
      .forget-password-link {
        margin-left: auto;
      }
    }
  }
}
