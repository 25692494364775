@import "../../../scss/variables";
@import "../../../scss/general";

.search-page-container {
  .section-tag-container {
    ul {
      padding: 0;
    }
    .tag-chip {
      width: 9.5rem;
      border-radius: 50px !important;
      border: 1px solid;
      font-weight: bold !important;
      background-color: $faintgreyBg !important;
      color: $lightGrey;
      border-color: $faintGrey !important;
      &.active-tag {
        background-color: $blueChipBackground !important;
        color: $primaryColor !important;
        border-color: $blueChipText !important;
      }
    }
  }
}

.search-page-range-filters {
  &.mobile-version {
    @media #{$media-tablet} {
      width: 80%;
    }
    @media #{$media-mobile} {
      width: 80%;
    }
    @media #{$media-mobile-md} {
      width: 110%;
    }
  }
  &.desktop-version {
    @media #{$media-desktop-xl} {
      width: 130%;
    }
  }
}
