@import "../../scss/variables";

.importer-profile-img {
  width: 3.75rem;
  height: 3.75rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &.no-img {
    background-color: $blueChipBackground;
  }
}
.price-section,
.budget-range-section {
  @media #{$media-mobile-sm} {
    justify-content: space-between !important;
    .price,
    .budget {
      margin: 0 !important;
      padding-left: 0 !important;
    }
  }
}
