@import "../../../../scss/variables";

.exporter-profile-container {
  .header-section {
    width: 100%;
    height: 14rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 8rem;
    .overlay-container {
      position: absolute;
      padding-left: -2rem;
      background-color: rgba($color: $black, $alpha: 0.3);
      height: 100%;
      width: 100%;
      * {
        color: $white !important;
      }
    }

    @media #{$media-mobile} {
      height: 10rem;
      margin-bottom: 5rem;
    }
    .profile-img {
      width: 13rem;
      height: 13rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: -6.5rem;
      @media #{$media-mobile} {
        width: 9rem;
        height: 9rem;
        bottom: -4.5rem;
      }
    }
  }
}
