@import "../../../../scss/_variables.scss";
.add-showcase-container {
  .add-showcase-inputs-container {
    gap: 2rem;
    .add-showcase-form-field {
      width: calc(50% - 2rem);
      @media #{$media-tablet} {
        width: 100% !important;
      }
    }
  }
  .form-buttons-container {
    @media #{$media-mobile} {
      width: 100% !important;
      flex-wrap: nowrap !important;
      flex-direction: column;
      gap: 2rem;
    }
  }
  .company-details-container {
    .showcase-img-products-container {
      @media #{$media-desktop-lg} {
        display: flex;
        align-items: start;
        justify-content: space-between;
      }
    }
    .showcase-img-container {
      width: 28rem;
      height: 35rem;
      @media #{$media-desktop} {
        height: 33.5rem;
        width: 24.5rem;
        padding-bottom: 3rem;
      }
      @media #{$media-tablet} {
        height: 31.75rem;
        padding-bottom: 1rem;
      }
      @media #{$media-mobile} {
        width: 100%;
        height: 33.9rem;
        padding-bottom: 3rem;
      }
      @media #{$media-mobile-md} {
        width: 98%;
        height: 28.75rem;
        padding-bottom: 3rem;
      }
      @media #{$media-mobile-sm} {
        height: 24rem;
        width: 98%;
        padding-bottom: 3rem;
      }
      .block-title {
        @media #{$media-mobile-sm} {
          width: 90% !important;
        }
      }
    }
  }
  .company-cover-img-container {
    min-height: 10rem;
    max-height: 10rem;
    height: 10rem;
  }

  .company-cover-img-container-filled,
  .company-cover-img-container {
    min-height: 10rem;
    max-height: 10rem;
    height: 10rem !important;

    @media #{$media-desktop-lg} {
      min-height: 9.5rem;
      max-height: 9.5rem;
    }
    @media #{$media-desktop} {
      width: 100% !important;
      min-height: 5.75rem;
      max-height: 5.75rem;
    }
    @media #{$media-tablet} {
      min-height: 5.75rem;
      max-height: 5.75rem;
    }
    @media #{$media-mobile} {
      min-height: 2.75rem;
      max-height: 2.75rem;
    }
    @media #{$media-mobile-sm} {
      min-height: 1.8rem;
      max-height: 1.8rem;
    }
  }
  .products-container {
    .single-product {
      background-color: $faintgreyBg;
      height: 17rem;
      width: 30%;
      @media #{$media-mobile} {
        width: 100%;
      }
      border: 1px dashed $grey;
      &.filled-product {
        border: none;
        * {
          max-height: 100% !important;
        }
      }
      &.first-product-placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        * {
          color: $grey;
        }
        &:hover {
          background-color: $disabledGreyColor;
        }
        &.disabled {
          background-color: $disabledGreyColor;
        }
      }
      &.editable-product-card {
        position: relative;
        .clear-product-icon {
          position: absolute;
          top: 2px;
          right: 0px;
          cursor: pointer;
        }
      }
    }
  }
}
