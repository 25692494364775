@import "../../../scss/variables";

.nav-bar.MuiAppBar-root {
  background-color: $primaryColor !important;
  box-shadow: none !important;

  @media #{$media-desktop} {
    div.navbar-info-container
      > div.navbarTitle-user-btns
      > div.navbarTitle.combined-user {
      width: 50%;
    }
  }
  @media #{$media-tablet} {
    .navbar-info-container {
      justify-content: flex-end !important;
      .navbarTitle,
      .logo-container {
        display: none;
      }

      .navbarTitle-user-btns {
        width: fit-content !important;
      }
    }
  }
  @media #{$media-mobile} {
    .navbar-info-container {
      justify-content: end !important;
      .logo-container {
        width: 50% !important;
        height: 75% !important;
        img {
          height: 75% !important;
          width: 100% !important;
        }
        padding: 0;
      }
      .navbarTitle {
        display: none;
      }
      .navbarTitle-user-btns {
        width: fit-content !important;
      }
    }
  }

  @media #{$media-mobile-sm} {
    .navbar-info-container {
      justify-content: end !important;
      .logo-container {
        width: 40% !important;
        img {
          width: 100%;
        }
        padding: 0;
      }
      .navbarTitle {
        display: none;
      }
      .navbarTitle-user-btns {
        width: fit-content !important;
      }
    }
  }
}
