@import "../../scss/variables";

.swiper-container {
  .custom-swiper-button {
    position: absolute;
    top: calc(50% - 12px);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 2;
    background-color: $primaryColor;
    border-radius: 50%;
    color: $white;
    padding: 5px;
    &:hover {
      background-color: $primaryColor !important;
      color: $white !important;
    }
    &.custom-swiper-button-next {
      right: 0px;
    }
    &.custom-swiper-button-prev {
      left: 0px;
    }
    &.custom-swiper-button-disabled {
      background-color: $disabledGreyColor;
      &:hover {
        background-color: $disabledGreyColor !important;
      }
    }
  }
}
