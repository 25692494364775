@import "../../scss/variables";

.edit-btn {
  cursor: pointer;
  background-color: $white !important;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid $buttons !important;
  &:hover {
    background-color: rgba($buttons, 0.1) !important;
  }
}
