@import "../../scss/variables";

.display-style-icon {
  &:hover {
    path {
      stroke: $primaryColor !important;
    }
  }
}

.display-style-icon-active {
  stroke: $primaryColor !important;
}
