@import "../../scss/variables";

.suggest-category-modal {
  .upload-image-section {
    width: 15rem;
    height: 15rem;
    label {
      span[role="button"] {
        height: 100% !important;
      }
    }
    .placeholder-text {
      font-size: 10px !important;
      margin: 0 1rem;
    }
  }
}
