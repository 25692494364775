@import "../../../../scss/variables";

.add-edit-product-container {
  .product-inputs-container {
    gap: 2rem;

    .product-form-field {
      width: calc(50% - 2rem);
      @media #{$media-tablet} {
        width: 100% !important;
      }
    }
    .add-more-product-form-field {
      width: calc(50% - 4rem);
      @media #{$media-tablet} {
        width: calc(100% - 4rem) !important;
      }
    }
    .product-small-form-field {
      width: calc(33% - 2rem);
      @media #{$media-tablet} {
        width: 100% !important;
      }
    }
    .add-more-product-md-form-field {
      width: calc(45% - 2rem);
      @media #{$media-tablet} {
        width: 100% !important;
      }
    }
    .add-more-product-small-form-field {
      width: calc(30% - 2rem);
      @media #{$media-tablet} {
        width: calc(100% - 4rem) !important;
      }
      &.currency-input {
        @media #{$media-tablet} {
          width: calc(100% - 2.5rem) !important;
        }
      }
    }
    div.input-wrapper.product-description
      > div.MuiTextField-root
      > div {
      padding-bottom: 3.5rem !important;
    }
    div.input-wrapper.product-description
      > div.MuiTextField-root
      > div {
      padding-bottom: 3.5rem !important;
    }
  }
  .field-array-container {
    .product-inputs-container {
      display: flex;
      align-items: start !important;
      gap: 2rem;
    }
  }

  .form-buttons-container {
    .product-inputs-container {
      @media#{$media-mobile} {
        button {
          width: 100% !important;
        }
      }
    }
  }

  @media #{$media-mobile} {
    div.add-more-btn-container > button {
      width: 10% !important;
      padding: 5px !important;
      min-width: fit-content !important;
      > span {
        > span > span {
          display: none;
          padding: 0px !important;
        }
      }
    }
    .form-buttons-container {
      .product-inputs-container {
        justify-content: center !important;
        button {
          width: 100% !important;
        }
      }
    }
  }

  .images-container {
    width: 100% !important;
    gap: 2rem;
    @media #{$media-tablet} {
      display: flex;
      justify-content: start !important;
      align-items: center !important;
    }
    @media #{$media-mobile} {
      display: flex;
      justify-content: center !important;
      align-items: center !important;
    }
  }
  .single-image-container {
    height: 13rem;
    border: 1px solid $grey;
    border-radius: 8px;
    width: calc(20% - 2rem);

    .product-image-icon,
    .product-image-icon-active {
      display: none !important;
    }
    &:hover {
      .product-image-icon,
      .product-image-icon-active {
        display: block !important;
      }
    }

    @media #{$media-desktop} {
      width: calc(33% - 2rem);
    }
    @media #{$media-tablet} {
      width: calc(33% - 2rem);
    }
    @media #{$media-mobile} {
      width: 60%;
    }
    @media #{$media-mobile-sm} {
      width: 100%;
    }
  }

  .default-single-image-container {
    border: 1px solid $primaryColor;
  }

  .container-options {
    height: 10%;
  }
  .product-image {
    height: calc(90% - 1rem);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 80%;
      height: 80%;
    }
  }

  .video-react-big-play-button {
    position: absolute !important;
    left: 47% !important;
    top: 47% !important;
    @media #{$media-desktop} {
      left: 43% !important;
      top: 44% !important;
    }
    @media #{$media-tablet} {
      font-size: 2rem !important;
      left: 40% !important;
      top: 42% !important;
    }
    @media #{$media-mobile} {
      font-size: 1rem !important;
      left: 40% !important;
      top: 40% !important;
    }
  }

  .product-image-icon {
    path {
      fill: $grey;
    }
    &:hover {
      path {
        fill: $primaryColor;
      }
    }
  }
  .product-image-icon-active {
    path {
      fill: $primaryColor;
    }
    circle {
      fill: $primaryColor;
    }
  }
  .remove-add-more-container {
    @media #{$media-tablet} {
      width: 100% !important;
    }
    @media #{$media-mobile} {
      width: 100% !important;
    }
  }

  .remove-add-more-component {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $lightGreyBackground;
    border-radius: 50% !important;
    &.add-component {
      border: 1px solid $primaryColor;
      svg {
        color: $primaryColor !important;
      }
    }
  }

  #productDescription {
    .ql-container {
      height: 10rem !important;
    }
  }

  .title-optional-add-more-container {
    @media #{$media-desktop-lg} {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      justify-content: center !important;
    }
    @media #{$media-desktop} {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      justify-content: center !important;
    }
    @media #{$media-tablet} {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      justify-content: center !important;
    }
    @media #{$media-mobile} {
      display: flex !important;
      flex-direction: column !important;
      align-items: start !important;
      justify-content: center !important;
      h6 {
        width: min-content !important;
      }
    }
    @media #{$media-mobile-sm} {
      h6 {
        width: min-content !important;
      }
      display: flex !important;
      flex-direction: column !important;
      align-items: start !important;
      justify-content: start !important;
    }
  }
  .title-optional-container {
    display: flex !important;
    justify-content: start !important;
    @media #{$media-mobile} {
      display: flex !important;
      flex-direction: column !important;
      align-items: start !important;
      justify-content: start !important;
    }
    @media #{$media-mobile-sm} {
      display: flex !important;
      flex-direction: column !important;
      align-items: start !important;
      justify-content: start !important;
    }
  }
  .keywords-container {
    .title-tips-container {
      display: flex !important;
      flex-direction: row !important;
      @media #{$media-mobile-sm} {
        h6 {
          width: min-content !important;
        }
      }
    }
  }

  .catalogue-specification-container {
    .upload-file-button-container {
      width: 100% !important;
      display: flex;
      flex-direction: row !important;
      word-break: keep-all;

      @media #{$media-desktop-lg} {
        div {
          width: 100%;
        }
        display: flex !important;
        flex-direction: row !important;
        justify-content: start !important;
        align-items: center !important;
      }
      @media #{$media-desktop} {
        div {
          width: 100%;
        }
        display: flex !important;
        flex-direction: column !important;
        justify-content: start !important;
        align-items: Start !important;
      }

      .MuiFormLabel-root.MuiInputLabel-root.upload-file-button {
        transform: none !important;
      }

      .upload-file-button {
        width: fit-content !important;
        cursor: pointer;
        background-color: $uploadButtonBackground;
        border-radius: 8px;
        font-weight: bold;
        padding: 0.8rem 2rem;
        font-size: 12px;
        color: $lightGrey;
        border: thin solid $grey;
        @media #{$media-mobile-sm} {
          padding: 1rem;
        }
        &:hover {
          background-color: $lightBlue;
        }
      }
    }
  }
  .helper-text-container {
    color: $lightGrey;
    position: relative !important;
    border-radius: 8px;
    background-color: $requestHelpLightGrey;
    padding: 12px 20px !important;
    position: relative !important;
    width: 493px;
    height: 42px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px;
    .highlighted-text {
      cursor: pointer;
      text-decoration-color: $primaryColor;
      &:hover {
        text-decoration: underline !important;
        font-weight: bold;
      }
    }
    @media #{$media-mobile} {
      padding: 22px 20px !important;
    }
    @media #{$media-mobile-sm} {
      display: flex;
      flex-direction: column;
      padding: 42px 20px !important;
    }
  }
  .helper-text-needed-container {
    color: $textColor;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
    background-color: $requestHelpLightGrey;
    padding: 12px 20px !important;
    position: relative !important;
    width: 493px;
    height: 42px;
  }
}
