/* ============= Colors ============= */

$theme-colors: ();

$primaryColor: #0071bc !default;
$secondary: #22b573 !default;

$transparentPrimaryColor: #21a1f6b2 !default;
$mainSectionBackgroundColor: #f6f6f6 !default;
$mediumPrimary: #338dc9 !default;
$error: #d32f2f !default;
$successColor: #22b573 !default;
$warning: #e8b42d !default;
$darkBlue: #003866 !default;
$lightBlue: #e5f4ff !default;
$inputsBorder: #e8e8e8 !default;
$titlesColor: #34464e !default;
$buttons: #0071bc !default;
$textColor: #455a64 !default;
$lightTextColor: #5a6d76 !default;
$black: #000000 !default;
$faintBlueBg: #f2fbff !default;
$mediumBlueBg: #def3ff !default;
$mediumDarkBlueBg: #bfe1f3 !default;
$faintPinkBg: #f9ede3 !default;
$faintgreyBg: #f9fafb !default;
$white: #ffffff !default;
$lightGrey: #8f8f8f !default;
$faintGrey: #f5f5f5 !default;
$borderColor: #f0f0f0 !default;
$borderColorMedium: #dddddd !default;
$greenChipBackground: #e1fcf1 !default;
$greenChipText: #22b573 !default;
$yellowChipBackground: #fff8cf !default;
$yellowChipText: #debc0a !default;
$orangeChipBackground: #ffefd2 !default;
$orangeChipText: #e8b42d !default;
$redChipBackground: #fff2f2 !default;
$redChipText: #d32f2f !default;
$blueChipBackground: #f1faff !default;
$faintBlueChipBackground: #f6fcff !default;
$blueChipText: #1699f1 !default;
$lightGreyBackground: #f8f8f8 !default;
$mediumGreyBackground: #f4f0f0 !default;
$mediumGreyText: #969191 !default;
$grey: #808080 !default;
$darkgrey: #a9a9a9 !default;
$buttonLightBackground: #e7e7e7 !default;
$uploadButtonBackground: #f9fafb !default;
$uploadButtonHelperTextBackground: #fdfdfd !default;
$white: #ffffff !default;
$faintRedBg: #fff3f3 !default;
$greyText: #455a64 !default;
$warningChipBackground: #fef6e2 !default;
$warningChipText: #dea71b !default;
$faintGreen: #f2fff9 !default;
$feintGreyText: #e6f4fa !default;
$lightFaintBlue: #21a1f6 !default;
$disabledGreyColor: #cfd2d5 !default;
$lightGreySeparator: #e6e6e6 !default;
$lightBlueHover: #f5fbff !default;
$filtersBg: #fcfcfc !default;
$greyGuideBorder: #e3e1e1 !default;
$requestHelpLightGrey: #fbfdff !default;

$media-desktop-xxl: "only screen and (min-width : 1600px)";
$media-desktop-xl: "only screen and (min-width : 1400px)";
$media-desktop-lg: "only screen and (min-width : 1200px)";
$media-desktop: "only screen and (max-width : 1200px)";
$media-tablet: "only screen and (max-width : 900px)";
$media-tablet-sm: "only screen and (max-width : 768px)";
$media-mobile: "only screen and (max-width : 600px)";
$media-mobile-md: "only screen and (max-width : 400px)";
$media-mobile-sm: "only screen and (max-width : 320px)";
