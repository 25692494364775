@import "../../scss/variables";

.btn {
  text-transform: none !important;
  box-shadow: none !important;
  padding: 8px 20px !important;
}
.MuiButton-containedPrimary {
  &:hover {
    background-color: $primaryColor !important;
    color: $white !important;
  }
  &.bg-danger:hover {
    background-color: $error !important;
  }
  &.reversed-color,
  &.reversed-color:hover {
    background-color: $white !important;
    color: $primaryColor !important;
  }
  &.faint-btn {
    background-color: $mediumPrimary !important;
  }
  &.danger-btn,
  &.danger-btn:hover {
    color: $white !important;
    background-color: $error !important;
    svg {
      color: $white !important ;
    }
  }
}
.MuiButton-outlinedPrimary {
  &:hover {
    color: $primaryColor !important;
    svg {
      color: $primaryColor !important ;
    }
  }
  &.white-bg-outlined,
  &.white-bg-outlined:hover {
    background-color: $white !important;
  }
  &.reversed-color,
  &.reversed-color:hover {
    background-color: $primaryColor !important;
    color: $white !important;
    border-color: $white;
  }
  &.white-outlined,
  &.white-outlined:hover {
    border-color: $white !important;
    color: $white !important;
    background-color: rgba($color: $white, $alpha: 0.2);
  }
  &.danger-btn,
  &.danger-btn:hover {
    color: $error !important;
    border-color: $error;
    background-color: $white;
    svg {
      color: $error !important ;
    }
  }
}

.Mui-focusVisible {
  background-color: $primaryColor !important;
}
.MuiButton-outlinedPrimary.Mui-focusVisible {
  color: $primaryColor !important;
}

// adding classes to override MUI hover and focus styles all over the app

.btn:focus,
.btn:hover {
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn.no-shadow:focus,
.btn.no-shadow:hover {
  box-shadow: none !important;
}

.btn-block {
  width: 100%;
}
