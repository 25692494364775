@import "./variables";

html,
body {
  margin: 0;
  font-family: "Poppins-Regular" !important;
}
.error-msg {
  color: #d32f2f;
  margin: 5px 0;
}

hr {
  background-color: $grey;
  margin: 20px 0 12px 0;
}

.hint {
  color: $grey;
}

.wrapper-bg {
  background-color: $white;
  padding: 40px 30px;
  border-radius: 16px;
}

.error-border {
  border: 2px solid #d32f2f !important;
}

.err {
  color: #d32f2f;
}

.err-asterisk {
  color: #d32f2f;
}

.warning {
  color: #e8b42d;
}

.success {
  color: #22b573;
}

.opacity_7 {
  opacity: 0.7;
}

.opacity-6 {
  opacity: 0.6;
}

.pointer {
  cursor: pointer !important;
}

.content-wrapper {
  background-color: white;
  margin: 2.071rem;
}

.text-underline {
  text-decoration: underline !important;
}

// To override browser autocomplete inputs styling
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-border-radius: 10px !important;
}
.empty-state-create-button {
  width: fit-content !important;
}
.MuiInputBase-root,
.MuiInputBase-root:hover {
  fieldset {
    border-color: $inputsBorder !important;
  }
}

.primary-bg {
  background-color: $primaryColor !important;
}

.light-error-bg {
  background-color: $redChipBackground !important;
}
.light-primary-bg {
  background-color: $blueChipBackground !important;
}
.faint-primary-bg {
  background-color: $faintBlueChipBackground !important;
}
.dark-blue-bg {
  background-color: $darkBlue;
}
.white-bg {
  background-color: $white;
}
.pink-bg {
  background-color: $faintPinkBg;
}
.faint-blue-bg {
  background-color: $faintBlueBg;
}
.light-blue-bg {
  background-color: $lightFaintBlue;
}
.light-faint-blue-bg {
  background-color: $lightBlue;
}
.medium-blue-bg {
  background-color: $mediumBlueBg;
}
.medium-dark-blue-bg {
  background-color: $mediumDarkBlueBg;
}
.dark-grey-bg {
  background-color: $darkgrey !important;
}
.faint-grey-bg {
  background-color: $faintgreyBg !important;
}
.grey-bg {
  background-color: $faintgreyBg !important;
}
.success-bg {
  background-color: $successColor !important;
}
.disabled-bg {
  background-color: $disabledGreyColor !important;
}

.line-through {
  text-decoration: line-through;
}

.scroll-within {
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: 7px;
    background: $faintGrey;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }
  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
    margin-bottom: 15px;
  }
  &::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
    margin-top: 15px;
  }
}
.light-box-shadow {
  -webkit-box-shadow: 0px 4px 20px 0px #0000000f;
  -moz-box-shadow: 0px 4px 20px 0px #0000000f;
  box-shadow: 0px 4px 20px 0px #0000000f;
}

.highlighted-text {
  color: $primaryColor;
}

.primary-link {
  color: $primaryColor;
  text-decoration: underline;
  font-weight: 600;
}

.box-shadow-hover {
  transition: all 0.5s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(0, 113, 188, 0.12);
  }
}

.medium-border {
  border: 1px solid $borderColorMedium !important;
}

.light-border {
  border: 1px solid $borderColor !important;
}

.light-border-bottom {
  border-bottom: 1px solid $borderColor !important;
}

.light-border-right {
  border-right: 1px solid $borderColor !important;
  &.responsive-border {
    @media #{$media-mobile} {
      border-right: 0 !important;
      border-bottom: 1px solid $borderColor !important;
    }
  }
}

.circular-border-radius {
  border-radius: 50%;
}

.MuiChip-root {
  font-weight: 600 !important;
  font-size: 0.85rem !important;
  border-radius: 8px !important;
  padding: 0.5rem !important;
  &.yellow-chip {
    background-color: $yellowChipBackground !important;
    color: $yellowChipText !important;
  }
  &.orange-chip {
    background-color: $orangeChipBackground !important;
    color: $orangeChipText !important;
  }
  &.green-chip {
    background-color: $greenChipBackground !important;
    color: $greenChipText !important;
  }
  &.red-chip {
    background-color: $redChipBackground !important;
    color: $redChipText !important;
  }
  &.blue-chip {
    background-color: $blueChipBackground !important;
    color: $blueChipText !important;
  }
  &.grey-chip {
    background-color: $faintgreyBg !important;
    color: $textColor !important;
  }
  &.medium-grey-chip {
    background-color: $mediumGreyBackground !important;
    color: $mediumGreyText !important;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.item-cards-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.width-max-content {
  width: max-content;
}

.width-fit-content {
  width: fit-content;
}

.primary-border {
  border: 1px solid $primaryColor;
}

.thick-primary-border {
  border: 2px solid $primaryColor;
}

.light-primary-border {
  border: 1px solid $lightFaintBlue;
}

.light-blue-tooltip-stroke-icon.MuiTooltip-tooltip {
  ul {
    list-style-type: none !important;
  }
  width: 75% !important;
  background-color: $blueChipBackground !important;
  color: $greyText;
  path {
    stroke: $greyText;
  }
}

.light-blue-tooltip-fill-icon.MuiTooltip-tooltip {
  ul {
    list-style-type: none !important;
  }
  width: 75% !important;
  background-color: $blueChipBackground !important;
  color: $greyText;
  path {
    fill: $greyText;
  }
}
.light-blue-tooltip-img-icon.MuiTooltip-tooltip {
  ul {
    list-style-type: none !important;
  }
  // width: 75% !important;
  background-color: $blueChipBackground !important;
  color: $greyText;
  path {
    fill: $greyText;
  }
}

.gradient-bg {
  background: rgb(233, 248, 255);
  background: linear-gradient(
    270deg,
    rgba(233, 248, 255, 1) 0%,
    rgba(221, 244, 255, 1) 50%,
    rgba(209, 240, 255, 1) 100%
  );
}

.word-break {
  word-break: break-word;
}

.nowrap-table {
  table {
    thead {
      th {
        white-space: nowrap;
      }
    }
    tbody {
      td {
        white-space: nowrap;
      }
      tr:last-child > td {
        border-bottom: 0 !important;
      }
    }
  }
}

.hover-table-row {
  tbody {
    tr:hover {
      background-color: $lightBlueHover !important;
    }
  }
}

.search-input-wrapper-width {
  @media #{$media-desktop-lg} {
    width: 75% !important;
  }
  @media #{$media-desktop} {
    width: 85% !important;
  }
  @media #{$media-tablet} {
    width: 80% !important;
  }

  @media #{$media-mobile} {
    width: 100% !important;
    input::placeholder {
      font-size: 12px;
    }
    input {
      padding-left: 0 !important;
    }
  }
  @media #{$media-mobile-sm} {
    width: 100% !important;
  }
}

.logo-img {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
}

.filters-col {
  background-color: $faintgreyBg;
  padding: 24px 16px;
  .MuiPaper-root {
    background-color: $faintgreyBg;
  }
}
.filters-section {
  height: calc(100vh - 270px);
  overflow-x: hidden;
  @media #{$media-desktop-xxl} {
    height: auto;
  }
}

.list-action-icon {
  &:hover {
    path {
      stroke: $primaryColor !important;
    }
  }
}

.light-blue-border {
  border: 1px solid $lightFaintBlue;
}

.download-instructions-banner-container {
  .main-text {
    color: $faintgreyBg !important;
  }
  * {
    color: $white;
  }
}
