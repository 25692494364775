@import "../../../scss/variables";

.date-range-filter {
  .collabsible-filter {
    .MuiAccordionSummary-content {
      margin: 0.5rem 0 !important;
    }
    .MuiAccordionSummary-root {
      min-height: 35px !important;
      padding: 0 !important;
    }
    .MuiAccordionDetails-root {
      padding: 0 !important;
    }
  }
  .MuiInputBase-input {
    padding: 0.5rem 1rem;
  }
}
