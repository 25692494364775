@import "../../scss/variables";
.voice-input-container {
  color: $textColor;
  background-color: $feintGreyText;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  margin: 0 20px;
  margin-bottom: -50px;

  padding: 12px, 20px, 12px, 20px !important;
  position: relative !important;
  top: -50px !important;
  height: 42px;
  .active-voice-record-container {
    color: $textColor;
    .record-text {
      @media #{$media-tablet} {
        display: block !important;
      }
      @media #{$media-mobile} {
        display: block !important;
      }
      &.recording-active {
        @media #{$media-mobile-sm} {
          display: none !important;
        }
      }
    }
    .record-circle {
      width: 10px !important;
      height: 10px !important;
      border-radius: 50% !important;
      background-color: $error;

      @media #{$media-desktop-lg} {
        display: none !important;
      }
      @media #{$media-desktop} {
        display: none !important;
      }
      @media #{$media-tablet} {
        display: none !important;
      }
      @media #{$media-mobile} {
        display: none !important;
      }
      @media #{$media-mobile-sm} {
        display: block !important;
      }
    }
  }
  .text-icon-container {
    color: $primaryColor !important;
    .record-text {
      &:hover {
        cursor: pointer;
        text-decoration: underline !important;
      }
    }
  }
}
.voice-input-container-closed-voice {
  margin: 0px;
  margin-bottom: -50px;
  background-color: transparent !important;
  display: flex;
  justify-content: end;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  padding: 12px, 20px, 12px, 20px !important;
  position: relative !important;
  top: -50px !important;
  height: 42px;
  .record-icon {
    margin-right: 0.7rem !important;
    cursor: pointer;
  }
}
.description-type-selector {
  path,
  circle {
    fill: $lightFaintBlue;
  }
}
