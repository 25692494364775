@import "../../scss/variables";

.delete-btn {
  height: 100% !important;
  background-color: $white !important;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid $error !important;
  color: $error;
  &:hover {
    cursor: pointer;
    background-color: rgba($error, 0.1) !important;
  }
}
