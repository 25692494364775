@import "../../scss/_variables.scss";

.auth-layout-container {
  min-height: 100vh;
  .main-content {
    min-height: calc(100vh - 40px);
  }
  .form-section {
    width: 60%;
    @media #{$media-tablet} {
      width: 100%;
    }
  }
  .component-section {
    width: 70%;
    @media #{$media-tablet} {
      width: 85%;
    }
    @media #{$media-mobile} {
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }
  .mobile-logo-container {
    display: none;
    @media #{$media-tablet} {
      display: block;
      .welcome-msg {
        padding: 0 !important;
      }
    }
    @media #{$media-mobile-sm} {
      padding: 1rem 0 !important;
    }
  }
  .desktop-logo-container {
    @media #{$media-tablet} {
      display: none;
    }
  }
  .gradient-section {
    width: 40%;
    height: auto;
    @media #{$media-tablet} {
      display: none;
    }
    .gradient-bg {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px 0 0 10px;
    }
    .content {
      width: 80%;
    }
    @media #{$media-tablet} {
      .content {
        width: 100%;
      }
      .main-msg {
        font-size: 1.5rem !important;
      }
      .sub-msg {
        font-size: 1rem !important;
      }
    }
  }
  .decreased-title-font {
    @media #{$media-tablet} {
      font-size: 2rem !important;
    }
    @media #{$media-mobile} {
      font-size: 1.5rem !important;
    }
  }
}
