@import "../../scss/variables";

.search-input-wrapper {
  .MuiInputBase-root {
    border-radius: 10px;
    .MuiInputBase-input {
      padding: 1rem 0.5rem;
    }
    input::placeholder {
      color: $titlesColor;
      white-space: normal; /* Allow wrapping */
      overflow-wrap: break-word; /* Break long words */
      text-overflow: clip; /* No cropping */
      margin-top: 0;
    }
  }
  &.large-placeholder-search {
    .MuiInputBase-root {
      @media #{$media-mobile} {
        input::placeholder {
          position: absolute;
          top: 0;
          padding-top: 5px;
        }
      }
    }
  }
  &.medium-placeholder-search {
    .MuiInputBase-root {
      @media #{$media-mobile-md} {
        input::placeholder {
          position: absolute;
          top: 0;
          padding-top: 5px;
        }
      }
    }
  }
  &.small-placeholder-search {
    .MuiInputBase-root {
      @media #{$media-mobile-sm} {
        input::placeholder {
          position: absolute;
          top: 0;
          padding-top: 5px;
        }
      }
    }
  }
}
