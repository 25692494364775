@import "../../../../scss/variables";

.registeration-page {
  .stepper-wrapper {
    .MuiStepConnector-root {
      left: calc(-75% + 20px);
      right: calc(60% + 20px);
      @media #{$media-desktop} {
        left: calc(-67% + 20px);
      }
      @media #{$media-mobile-sm} {
        left: calc(-55% + 20px);
      }
    }
    .contact-info-step {
      padding-left: 0;
      .MuiStepLabel-root {
        width: fit-content;
      }
    }
  }
  .company-logo {
    width: 13rem;
    height: 13rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media #{$media-mobile} {
      width: 9rem;
      height: 9rem;
      bottom: -4.5rem;
    }
    .error-container {
      width: 20rem;
    }
  }
}
