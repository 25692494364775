@import "../../../../../scss/variables";

.show-case-container {
  .single-show-case-slide {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 30rem;
  }
  .product-showcase-slider {
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide div {
      height: 100%;
      border-radius: 10px !important;
    }
  }
  .slider-info-container {
    position: relative;
    .description {
      border-radius: 10px !important;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      background-color: rgba($color: $black, $alpha: 0.5);
      .showcase-info {
        > div {
          p {
            @media #{$media-mobile} {
              font-size: 1.5rem !important;
            }
          }
          @media #{$media-mobile} {
            font-size: 0.1rem !important;
          }
        }
      }
      button {
        font-size: 0.8rem !important;
      }
      .showcase-info > div {
        .showcase-description {
          @media #{$media-tablet} {
            font-size: 0.75rem !important;
          }
          @media #{$media-mobile} {
            font-size: 0.6rem !important;
          }
          @media #{$media-mobile-sm} {
            font-size: 0.55rem !important;
          }
        }
        .showcase-name {
          @media #{$media-mobile-sm} {
            font-size: 1.5rem !important;
          }
        }
      }
    }
    .slider {
      height: 35rem !important;
      @media #{$media-desktop} {
        height: 17rem !important;
      }
      @media #{$media-tablet} {
        height: 15rem !important;
      }
      @media #{$media-mobile} {
        width: 100%;
        height: 34.9rem;
      }
      @media #{$media-mobile-md} {
        height: 30rem;
      }
      @media #{$media-mobile-sm} {
        height: 25rem;
      }
    }
  }
  .products-container {
    .single-product {
      background-color: $faintgreyBg;
      height: 17rem;
      width: 30%;
      @media #{$media-desktop} {
        width: 23%;
      }
      @media #{$media-tablet} {
        width: 30%;
      }
      @media #{$media-mobile} {
        width: 48%;
      }
      @media #{$media-mobile-md} {
        width: 47%;
      }
      @media #{$media-mobile-sm} {
        width: 100%;
      }
      border: 1px dashed $grey;
      &.filled-product {
        border: none;
        * {
          max-height: 100% !important;
        }
      }
      &.first-product-placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        * {
          color: $grey;
        }
        &:hover {
          background-color: $disabledGreyColor;
        }
        &.disabled {
          cursor: not-allowed !important;
          background-color: $disabledGreyColor;
        }
      }
      &.editable-product-card {
        position: relative;
        .clear-product-icon {
          position: absolute;
          top: 2px;
          right: 0px;
          cursor: pointer;
        }
      }
    }
  }
  .showcase-products-card {
    background-color: $blueChipBackground !important;
  }
}
