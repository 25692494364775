@import "../../scss/variables";

.notification-snackbar-container {
  .notification-snackbar {
    max-width: 45rem;
    .MuiSnackbarContent-root {
      background-color: $blueChipBackground;
      border: 1px solid $blueChipText;
      border-radius: 8px;
      justify-content: space-between;
      padding: 0.5rem;
      .title {
        color: $blueChipText;
      }
      .body {
        color: $lightGrey;
      }
      .close-icon {
        color: $blueChipText;
      }
    }
  }
  .MuiSnackbarContent-message {
    width: 100%;
    @media #{$media-mobile} {
      .message-container svg {
        width: 11%;
      }
    }
    @media #{$media-mobile-sm} {
      .message-container svg {
        width: 25%;
      }
    }
  }
}
