@import "../../scss/variables";

.notifications-button-wrapper {
  background-color: rgba($color: white, $alpha: 0.2);
  &:hover {
    background-color: rgba($color: white, $alpha: 0.3);
  }
}
.notifications-menu {
  width: 27rem;
  border-radius: 10px !important;
}
.notification-item {
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}
