@import "../../../scss/variables";

.exporters-details-container {
  .text-section {
    @media #{$media-desktop-xl} {
      width: 30% !important;
    }
  }
  .exporter-info-section {
    position: relative;
    .exporter-image {
      min-height: 10rem;
      background-position: center;
      background-repeat: no-repeat;
      &.size-cover {
        background-size: cover;
      }
      &.size-contain {
        background-size: contain;
      }
    }
    .exporter-info {
      position: absolute;
      background-color: rgba($color: $black, $alpha: 0.3);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 10rem;
    }
  }
  .border-bottom {
    border-bottom: 2px solid $faintGrey;
  }
  .controls-section {
    .filters-sort-buttons-container {
      @media #{$media-tablet-sm} {
        width: 100% !important;
      }
    }
  }
}
