@import "../../../scss/variables";

.show-hide-showcases-modal {
  .MuiPaper-root {
    max-width: fit-content;
    .showcase-container {
      width: 95%;
    }
    .MuiFormControlLabel-root {
      margin-right: 0;
    }
    .slider {
      @media #{$media-desktop} {
        height: 100% !important;
      }
      @media only screen and (max-width: 990px) {
        height: 15rem !important;
      }
    }
    .MuiDialogActions-root {
      .MuiButtonBase-root {
        width: auto !important;
      }
    }
    .alert-container {
      .MuiPaper-root {
        max-width: 100%;
      }
    }
    .MuiDialogContent-root {
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: transparent;
      }

      &::-webkit-scrollbar {
        width: 7px;
        background: $faintGrey;
        // background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: white;
      }
      &::-webkit-scrollbar-track-piece:end {
        background-color: transparent;
        margin-bottom: 15px;
      }
      &::-webkit-scrollbar-track-piece:start {
        background-color: transparent;
        margin-top: 15px;
      }
    }
  }
}
