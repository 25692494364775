@import "../../../scss/variables";

.pricing-details-page {
  .active-plan {
    background-color: $primaryColor !important;
    .plan-text {
      color: $white !important;
    }
    .features {
      margin-bottom: 5rem;
    }
  }
  .plan-card {
    position: relative;
  }
  .active-plan-tag {
    width: fit-content;
    position: absolute;
    top: -15px;
    left: 30%;
  }
}
