@import "../../scss/variables";

.input-wrapper {
  .MuiOutlinedInput-root {
    border-radius: 10px !important;
    &.Mui-error {
      border-color: $error !important; // Use the error color defined in your variables
      fieldset {
        border-color: $error !important; // Ensure the fieldset border is also red
      }
    }
    &.Mui-disabled {
      background-color: $uploadButtonBackground !important;
    }
  }

  .phone-input-field::placeholder {
    color: rgba(0, 0, 0, 0.26); 
    opacity: 1; 
    font-family: "Poppins-Regular" !important;
    font-size: 1rem;
  }

  .phone-input-container {
    position: relative;
    margin-bottom: 8px;
    .phone-input-label {
      position: absolute;
      top: -12px;
      left: 12px;
      background: white;
      padding: 0 4px;
      color: $titlesColor; 
      transition: color 0.3s;
      z-index: 1;
      &.focused {
        color: $primaryColor !important;
      }
    }
    .phone-input-field {
      &.error {
        border: 1px solid $error !important; 
      }
      &.error:focus {
        border: 2px solid $error !important;
      }
    }
  }
}
.react-tel-input {
  .form-control {
    width: 100% !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    border-color: $inputsBorder !important;
    border-radius: 10px;
    &:focus {
      border-color: $inputsBorder !important;
      box-shadow: 0 0 0 1px $inputsBorder !important;
    }
    &:focus {
      .phone-input-label {
        color: $primaryColor !important;
      }
    }
    &:active {
      .phone-input-label {
        color: $primaryColor !important;
      }
    }
  }
}
