@import "../../../../scss/variables";
.view-product-details-container {
  .page-title-buttons-container {
    @media #{$media-tablet} {
      display: flex !important;
      flex-direction: column !important;
      row-gap: 1rem;
      .page-title {
        width: 100%;
        text-align: center !important;
      }
      .title-buttons-container {
        width: 100% !important;
        display: flex !important;
        justify-content: space-between !important;
      }
    }
    @media #{$media-mobile} {
      display: flex !important;
      flex-direction: column !important;
      row-gap: 1rem;
      .page-title {
        width: 100%;
        text-align: center !important;
      }
      .title-buttons-container {
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
        .edit-btn,
        .delete-btn {
          width: 100% !important;
          display: flex !important;
          justify-content: center !important;
        }
      }
    }
    @media #{$media-mobile-sm} {
      display: flex !important;
      flex-direction: column !important;
      row-gap: 1rem;
      .page-title {
        width: 100%;
        text-align: center !important;
      }
      .title-buttons-container {
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
        .edit-btn,
        .delete-btn {
          width: 100% !important;
          display: flex !important;
          justify-content: center !important;
        }
      }
    }
  }

  .rejected-product-warning-container {
    margin-bottom: 2rem;
    background-color: $faintRedBg !important;
    color: $error;
    border: 1px solid $error;
    border-radius: 10px !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: start !important;
    align-items: start !important;
    padding: 1rem 2.5rem;
    @media #{$media-tablet} {
      padding: 1rem;
    }
    @media #{$media-mobile} {
      height: fit-content !important;
      padding: 1rem;
      svg {
        width: 2rem !important;
      }
      .chip-text {
        display: flex;
        flex-direction: column !important;
      }
    }
    @media #{$media-mobile-sm} {
      svg {
        width: 2.5rem !important;
      }
    }
    .warning-content-container {
      margin: 0.5rem 0 !important;
      padding: 0 !important;
      @media #{$media-desktop-lg} {
        padding-left: 3.5rem !important;
      }
      @media #{$media-desktop} {
        padding-left: 3.5rem !important;
      }
      @media #{$media-tablet} {
        padding-left: 3.5rem !important;
      }
      @media #{$media-mobile} {
        padding-left: 1rem !important;
      }
    }
  }

  .status-revert-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$media-tablet} {
      gap: 1rem;
      display: flex;
      flex-direction: column !important;
      justify-content: center !important;
      text-align: center;
    }
  }
}
