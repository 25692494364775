@import "../../scss/variables";

.alert-container {
  .MuiAlert-root {
    border-radius: 10px !important;
    align-items: start;
    &.MuiAlert-colorError {
      background-color: $redChipBackground;
      color: $error;
    }
    &.MuiAlert-colorWarning {
      background-color: $warningChipBackground;
      color: $warningChipText;
    }
    &.MuiAlert-colorInfo {
      background-color: $blueChipBackground;
      color: $primaryColor;
    }
    &.is-closable-alert {
      .MuiAlert-message {
        width: calc(100% - 20px);
      }
    }
    &.is-filled-closable-alert {
      .MuiAlert-message {
        width: 100%;
      }
    }
  }
}
