@import "../../scss/variables";

.switch-active-text {
  &.primary {
    color: $primaryColor;
  }
  &.success {
    color: $successColor;
  }
}
.switch-inactive-text {
  color: $grey !important;
}
.switchButton-container {
  width: fit-content !important;
}
