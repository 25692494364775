@import "../../scss/variables";

.snackbar-container {
  .snackbar.error {
    .MuiSnackbarContent-root {
      background-color: $redChipBackground;
      color: $redChipText;
      border: 1px solid $redChipText;
      border-radius: 8px;
    }
  }
  .snackbar.success {
    .MuiSnackbarContent-root {
      background-color: $greenChipBackground;
      color: $greenChipText;
      border: 1px solid $greenChipText;
      border-radius: 8px;
    }
  }
  .snackbar.info {
    .MuiSnackbarContent-root {
      background-color: $blueChipBackground;
      color: $blueChipText;
      border: 1px solid $blueChipText;
      border-radius: 8px;
    }
  }
  .MuiSnackbarContent-root {
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  .snackbar-icon {
    min-width: 2rem;
  }
}
