@import "../../../../scss/variables";
.product-request-currency-modal > div.MuiDialog-container > div {
  > div.MuiDialogActions-root {
    > button.MuiButtonBase-root.modal-cancel-btn {
      width: 50% !important;
      @media #{$media-mobile} {
        width: 70% !important;
      }
    }
  }
}
