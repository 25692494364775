@import "../../scss/variables";

.images-slider-container {
  .img-slide {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .default-img {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .vertical-slider {
    @media #{$media-tablet} {
      display: none;
    }
    .images-gallery-slider {
      .slick-slider {
        position: relative;
        .slick-prev {
          display: none !important;
        }
        .slick-next {
          position: absolute;
          right: 33%;
          top: 98%;
          &::before {
            content: "" !important;
            font-size: 0 !important;
          }
        }
      }
    }
    .img-slide {
      width: 100%;
      height: 8rem;
      @media #{$media-desktop} {
        width: 7rem;
        height: 6rem;
      }
    }
    .default-img {
      width: 100%;
      height: 34rem;
      @media #{$media-desktop} {
        height: 26rem;
      }
    }
  }
  .horizontal-slider {
    display: none;
    @media #{$media-tablet} {
      display: block;
    }
    @media #{$media-mobile} {
      display: none;
    }
    .img-slide {
      width: 5.5rem;
      height: 5.5rem;
    }
    .default-img {
      width: 23.5rem;
      height: 23.5rem;
      @media #{$media-tablet} {
        width: 100%;
        height: 23.5rem;
      }
    }
    .slick-prev {
      left: -10px;
    }
    .slick-next {
      right: -10px;
    }
  }
  .mobile-slider {
    display: none;
    @media #{$media-mobile} {
      display: block;
    }
    .img-slide {
      width: 100%;
      height: 24rem;
      @media #{$media-mobile-sm} {
        height: 20rem;
      }
    }
    .slick-prev {
      left: -10px;
    }
    .slick-next {
      right: -10px;
    }
  }
}
