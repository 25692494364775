@import "../../../../scss/variables";

.sub-category-details-container {
  .text-section {
    @media #{$media-desktop-xl} {
      width: 30% !important;
    }
  }
  .sub-category-info-section {
    position: relative;
    .sub-category-image {
      min-height: 12rem;
      background-position: center;
      background-repeat: no-repeat;
      &.size-cover {
        background-size: cover;
      }
      &.size-contain {
        background-size: contain;
      }
    }
    .sub-category-info {
      position: absolute;
      background-color: rgba($color: $black, $alpha: 0.3);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 12rem;
    }
  }

  .border-bottom {
    border-bottom: 2px solid $faintGrey;
  }
  .sub-categories-filter-button {
    @media #{$media-mobile} {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
}
.sub-categories-range-filters {
  &.mobile-version {
    @media #{$media-tablet} {
      width: 80%;
    }
    @media #{$media-mobile} {
      width: 90%;
    }
    @media #{$media-mobile-sm} {
      width: 110%;
    }
  }
  &.desktop-version {
    @media #{$media-desktop-xl} {
      width: 130%;
    }
  }
}
