@import "../../../scss/variables";

.search-website-container {
  position: relative;
  .MuiInputBase-root {
    background-color: $white;
    border-radius: 0 8px 8px 0;
    @media #{$media-mobile} {
      padding: 0 0.5rem;
    }
    .MuiInputBase-input {
      padding: 0.6rem 0.5rem;
    }
  }
  .search-input-wrapper {
    width: -webkit-fill-available;
    fieldset {
      border-left: none;
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
    }
  }
  .advanced-search-button {
    width: 10rem;
    .menu-button {
      border-radius: 8px 0 0 8px;
      padding: 0.57rem 0.5rem;
    }
  }
  .suggestions-container {
    pointer-events: auto;
    position: absolute;
    width: 100%;
    top: 2.5rem;
    z-index: 1000;
    border-radius: 0 0 8px 8px;
    .suggestions-header {
      border-bottom: 1px solid $borderColor;
    }
    .suggestion {
      &:hover {
        background-color: $lightGreyBackground;
      }
      &.highlighted {
        background-color: $lightGreyBackground;
      }
    }
  }
}
