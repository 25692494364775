@import "../../scss/variables";

.video-player-container {
  @media #{$media-desktop-lg} {
    width: 65% !important;
  }
  @media #{$media-desktop} {
    width: 65% !important;
  }
  @media #{$media-tablet} {
    width: 65% !important;
  }
  @media #{$media-mobile} {
    width: 100% !important;
    height: 15rem;
  }
  @media #{$media-mobile-sm} {
    width: 100% !important;
  }
}
.vds-play-button {
  @media #{$media-mobile} {
    position: relative !important;
    top: 0 !important;
  }
  @media #{$media-mobile-sm} {
    position: relative !important;
    top: -50% !important;
  }
}
