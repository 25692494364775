@import "../../../scss/variables";
.profile-container {
  .data-btn-container {
    position: relative;
    .edit-btn-container {
      position: absolute;
      right: 20px;
      top: 20px;

      @media #{$media-mobile} {
        right: 15px;
        top: 15px;
      }
    }
    .profile-img-data-container {
      .profile-img {
        width: 8.75rem;
        height: 8.75rem;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  .transactions-table-container {
    table {
      thead {
        th:nth-child(1) {
          width: 20% !important;
        }
        th {
          white-space: nowrap;
        }
      }
      tbody > tr:last-child > td {
        border-bottom: 0 !important;
      }
      tbody {
        td {
          white-space: nowrap;
        }
      }
    }
  }
}
