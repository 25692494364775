@import "../../scss/variables";

.filter-modal-drawer {
  .action-btn {
    width: 40% !important;
    @media #{$media-mobile} {
      width: 50% !important;
    }
  }
}
