@import "../../../../scss/variables";

.viewed-file-card {
  width: 28rem;
  @media #{$media-mobile} {
    width: 23rem;
  }
  @media #{$media-mobile-md} {
    width: 17rem;
  }
  .file-view {
    height: 3.5rem;
    width: 2.7rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
