@import "../../../scss/variables";

.MuiAppBar-root.basic-layout-navbar {
  background-color: $primaryColor !important;
  box-shadow: none !important;
  height: 70px;
  @media #{$media-mobile} {
    .navbar-info-container {
      .logo-container {
        padding: 0;
      }
      .navbarTitle {
        display: none;
      }
    }
  }

  @media #{$media-mobile-sm} {
    .navbar-info-container {
      .navbarTitle {
        display: none;
      }
    }
    .logo-title-container {
      width: 55%;
    }
  }
}
