@import "../../../scss/variables";

.sub-accounts-table-container {
  table {
    thead {
      th {
        white-space: nowrap;
      }
    }
    tbody > tr:last-child > td {
      border-bottom: 0 !important;
    }
    tbody {
      tr {
        td {
          white-space: nowrap;
        }
      }
    }
  }
  .table-header {
    @media #{$media-mobile-sm} {
      display: flex;
      flex-direction: column !important;
      gap: 1rem;
    }
  }
}

.filter-content-container {
  height: 100% !important;
}

.sub-accounts-list {
  div.page-title {
    @media #{$media-mobile} {
      display: flex !important;
      flex-direction: column !important;
      gap: 1rem;
    }
    @media #{$media-mobile-md} {
      .title-buttons-container {
        display: flex !important;
        flex-direction: row !important;
        gap: 1rem;
        * {
          font-size: small !important;
        }
      }
    }
    @media #{$media-mobile-sm} {
      .title-buttons-container {
        * {
          font-size: x-small !important;
        }
        svg {
          width: 0.9rem !important;
        }
      }
    }
  }
  .search-input-wrapper {
    @media #{$media-desktop-lg} {
      width: 75% !important;
    }
    @media #{$media-desktop} {
      width: 85% !important;
    }
    @media #{$media-tablet} {
      width: 80% !important;
    }

    @media #{$media-mobile} {
      width: 80% !important;
    }
    @media #{$media-mobile-sm} {
      width: 100% !important;
    }
  }
}
