@import "../../../scss/variables";

.file-input-wrapper {
  .upload-file-button-container {
    .upload-file-button {
      width: fit-content !important;
      cursor: pointer;
      background-color: $uploadButtonBackground;
      border-radius: 8px;
      font-weight: bold;
      padding: 0.8rem 2rem;
      font-size: 12px;
      color: $lightGrey;
      border: 1px solid $grey;
      &:hover {
        background-color: $buttonLightBackground;
      }
      &.MuiInputLabel-root {
        transform: none;
      }
      &.disabled {
        background-color: $buttonLightBackground;
        cursor: default;
      }
    }
  }
}
