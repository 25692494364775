@import "../../scss/variables";

.table-container {
  .MuiTableHead-root {
    background-color: $lightGreyBackground;
  }
  th {
    font-weight: bold !important;
  }
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
