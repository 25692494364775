@import "../../../scss/variables";

.side-menu-container {
  background-color: white;
  height: 100vh;
  position: relative;
  padding: 0 1rem;

  .side-menu-footer {
    position: absolute;
    bottom: 0;
    .MuiListItem-root {
      width: 87%;
      &.closed-menu {
        width: 60%;
      }
    }
    .list-item-wrapper.closed-menu {
      .MuiListItemIcon-root {
        margin: 0;
      }
    }
  }

  .MuiListItem-root {
    color: $grey !important;
    .MuiListItemText-root {
      span {
        font-family: "Poppins-SemiBold";
        font-weight: bold;
      }
    }
  }
  .MuiListItem-root.Mui-selected {
    color: $primaryColor !important;
  }
  .MuiListItem-root:hover {
    background-color: $lightBlue !important;
    color: $primaryColor !important;
    border-radius: 10px;
    path {
      stroke: $primaryColor;
    }
  }
  .MuiListItemButton-root.Mui-selected,
  .MuiListItemButton-root.Mui-selected:hover,
  .MuiListItemButton-root:hover {
    color: $primaryColor !important;
    opacity: 1;
    border-radius: 10px;
    path {
      stroke: $primaryColor;
    }
  }
  .child-list-item {
    &.MuiListItem-root:hover {
      color: $primaryColor !important;
      background-color: transparent !important;
    }
    .MuiListItemButton-root {
      &.Mui-selected {
        background-color: transparent;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  .list-item-label {
    font-family: "Poppins-SemiBold";
    font-weight: bold;
  }
  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  *::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }
  *::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 20px;
  }
  *::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 20px;
  }
  @media #{$media-mobile} {
    height: 100%;
  }

  .list-item-label span {
    white-space: pre-wrap;
  }
  .list-item-wrapper {
    &.closed-menu {
      padding: 0.5rem 0.3rem;
    }
  }
  .child-sub-list {
    padding-left: 2rem;
    &.closed-menu {
      padding-left: 0;
    }
    .list-item-wrapper {
      padding: 0.5rem 0 0.5rem 1rem;
      &.closed-menu {
        padding: 0;
      }
    }
  }
}
