@import "../../../../scss/variables";

.product-request-details-container {
  .product-requests-details-section {
    position: relative;
    .product-requests-cover-image {
      min-height: 11.25rem;
      background-position: center;
      background-repeat: no-repeat;
      &.size-cover {
        background-size: cover;
      }
      &.size-contain {
        background-size: contain;
      }
    }
  }
  .not-found-container {
    .not-found-cover-img-container {
      .not-found-cover-img {
        @media #{$media-mobile} {
          width: 100%;
        }
      }
    }
  }
}
