@import "../../../scss/variables";

.verification-container {
  .verification-stepper {
    .step-count {
      width: 3.5rem;
      height: 3.5rem;
    }
    .MuiStepConnector-root {
      top: 25px;
      left: calc(-50% + 45px);
      right: calc(50% + 45px);
    }
  }
}
.bank-tansfer-container {
  .bank-info {
    .download-icon-container {
      color: $primaryColor;
    }
  }
}
