@import "../../../scss/variables";

.footer-section-container {
  .MuiInputBase-root {
    background-color: $white;
    border-radius: 8px 0 0 8px;
    width: 70%;
    input {
      &::placeholder {
        color: $greyText !important;
        font-size: 0.8rem !important;
      }
    }
  }
  .subscribe-btn {
    border-radius: 0 8px 8px 0 !important;
  }
  .subscribe-input-container {
    input::placeholder {
      color: $titlesColor !important;
    }
  }
}
