@import "../../../scss/variables";

.pricing-details-page {
  .user-saved-cards {
    .MuiFormControl-root,
    .MuiFormGroup-root,
    .MuiFormControlLabel-root {
      width: 100%;
    }
    .user-saved-card {
      width: 50%;
      @media #{$media-mobile} {
        width: auto;
      }
      .MuiTypography-root {
        width: 85%;
      }
    }
  }
}
