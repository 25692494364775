@import "../../../../scss/variables";

.select-default-card-modal {
  .user-saved-cards {
    .MuiFormControl-root,
    .MuiFormGroup-root,
    .MuiFormControlLabel-root {
      width: 100%;
    }
    .user-saved-card {
      width: 100%;
      .MuiTypography-root {
        width: 100%;
      }
    }
  }
}
