@import "../../../../scss/variables";

.view-image-modal-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $black, $alpha: 0.9);
  z-index: 99999;
  .img-container {
    width: 50%;
  }
  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: $black, $alpha: 0.9);
  }
}
