@import "../../scss/variables";

.custom-progress-bar-container {
  .MuiLinearProgress-root {
    height: 6px;
    border-radius: 50px;
    background-color: $white;
    .MuiLinearProgress-bar {
      border-radius: 50px;
    }
  }
}
