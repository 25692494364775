@import "../../../scss/variables";

.list-filter-container {
  .filter-search-input {
    &.MuiInputBase-root {
      background-color: $white;
    }
    .MuiSvgIcon-root {
      color: $textColor;
    }
    .MuiInputBase-input {
      padding: 0.5rem;
    }
    input::placeholder {
      font-size: 12px;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .collabsible-filter {
    .MuiAccordionSummary-content {
      margin: 0.5rem 0 !important;
    }
    .MuiAccordionSummary-root {
      min-height: 35px !important;
      padding: 0 !important;
    }
    .MuiAccordionDetails-root {
      padding: 0 !important;
    }
  }

  .filter-options-outer-container {
    &.fixed-height {
      overflow-y: auto;
      padding-left: 3px;
      height: 21rem;
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: transparent;
      }

      &::-webkit-scrollbar {
        width: 3px;
        background: $lightGreyBackground;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: white;
      }
      &::-webkit-scrollbar-track-piece:end {
        background-color: transparent;
        margin-bottom: 5px;
      }
      &::-webkit-scrollbar-track-piece:start {
        background-color: transparent;
        margin-top: 5px;
      }
    }
  }
}
