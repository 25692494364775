@import "../../../../scss/variables";
.category-details-container {
  .category-image {
    min-height: 18rem;
    background-position: center;
    background-repeat: no-repeat;
    &.size-cover {
      background-size: cover;
    }
    &.size-contain {
      background-size: contain;
    }
    .category-info {
      background-color: rgba($color: $black, $alpha: 0.3);
      height: 18rem;
      width: 100%;
    }
  }
  .gradient-bg {
    background: rgb(233, 248, 255);
    background: linear-gradient(
      270deg,
      rgba(233, 248, 255, 1) 0%,
      rgba(221, 244, 255, 1) 50%,
      rgba(209, 240, 255, 1) 100%
    );
    @media #{$media-tablet} {
      * {
        font-size: 1rem !important;
        button {
          * {
            font-size: 0.8rem !important;
          }
        }
      }
    }

    @media #{$media-tablet} {
      * {
        button {
          * {
            font-size: 0.8rem !important;
          }
        }
        .section-title {
          font-size: 1.4rem !important;
        }
        .section-content {
          font-size: 0.8rem !important;
        }
      }
    }
  }
  .contact-section-container {
    position: relative;
    .green-bg {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      z-index: -1;
      @media #{$media-tablet} {
        top: 0;
      }
    }
    .content {
      margin: 3% 0 !important;
      @media #{$media-desktop-lg} {
        margin: 3% 0 !important;
      }
    }
    @media #{$media-tablet} {
      * {
        button {
          * {
            font-size: 0.8rem !important;
          }
        }
        .section-title {
          font-size: 1.4rem !important;
        }
        .section-content {
          font-size: 0.8rem !important;
        }
      }
    }
  }
}
