@import "../../../scss/variables";
.about-us-container {
  .about-us-cover-image {
    min-height: 21.8rem;
    background-position: center;
    background-repeat: no-repeat;
    &.size-cover {
      background-size: cover;
    }
    &.size-contain {
      background-size: contain;
    }
  }
  .logo-about-container {
    .about-logo {
      @media #{$media-desktop} {
        width: 80%;
      }
      @media #{$media-tablet} {
        width: 80%;
      }
      @media #{$media-mobile} {
        width: 70%;
      }
      @media #{$media-mobile-md} {
        width: 70%;
      }
    }
  }
  .services-container {
    background: linear-gradient(to bottom, #e9f8ff, #ddf4ff, #d1f0ff);

    .service-icon-container {
      width: 4.3rem;
      height: 4.3rem;
    }
  }

  .light-grey-border {
    border-bottom: 1px solid $lightGrey;
  }
}
