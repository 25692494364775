@import "../../../scss/variables";
.change-password-container {
  .change-password-form {
    .form-buttons-container {
      @media #{$media-mobile} {
        width: 100% !important;
        flex-wrap: nowrap !important;
        flex-direction: column;
        gap: 2rem;
      }
    }
  }
}
