@import "../../scss/variables";

.MuiAutocomplete-listbox,
.MuiAutocomplete-noOptions {
  border: 1px solid gray;
  margin-top: 0.3rem !important;
  border-radius: 0.25rem !important;
}

.MuiAutocomplete-option {
  background-color: white !important;
  &:hover {
    background-color: $blueChipBackground !important;
    transition: all 0.2s;
  }
}

.select-wrapper {
  .MuiOutlinedInput-root {
    border-radius: 8px !important;
    &.Mui-disabled {
      background-color: $uploadButtonBackground !important;
    }
    &.Mui-error {
      border-color: $error !important;
      fieldset {
        border-color: $error !important;
      }
    }
  }
  .MuiInputLabel-root {
    &.Mui-focused {
      color: $primaryColor !important;
      span {
        color: $primaryColor !important;
      }
    }
    &.Mui-error {
      color: $titlesColor;
    }
  }
}

html[dir="rtl"] {
  .MuiAutocomplete-endAdornment {
    left: 9px;
    right: auto !important;
  }
  .select-wrapper {
    .MuiAutocomplete-inputRoot {
      padding-right: 9px !important;
    }
  }
}
